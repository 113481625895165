/* ::-webkit-scrollbar {
  -webkit-appearance: none;
} */
 
.la {
  height: 97px;
  background-color: rgba(255, 152, 0, 0); /* orange-50 with opacity 0 */
  border-radius: 8px; /* rounded-lg */
  border: 1px solid #4f46e5; /* border-indigo-800 */
  margin-top: 5px;
}
.mainbody {
  overflow-x: hidden;
  width: 100vw;
}
/* .ka {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
} */

.haa {
  height: 48px;
  border-radius: 8px;
  /* border: 1px solid #a7a3e8; */
  background: rgba(255, 241, 226, 0);
  margin-top: 5px;
}
.haa:focus {
  outline: 1px solid #659679;
}
.messagetextare:focus {
  outline: 1px solid #659679;
}
.border_color {
  border: 1px solid #82CBA8;
}

.error_border {
  border: 1px solid red;
}

.thank_you {
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.messagetextare {
  width: 100%;
  height: 97px;
  background-color: rgba(255, 152, 0, 0); /* orange-50 with opacity 0 */
  border-radius: 8px; /* rounded-lg */
  border: 1px solid #82CBA8;
  margin-top: 5px;
}
.gsw {
  width: 241px;
  height: 46px;

  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #659679; /* indigo-800 */
  border: 1px solid #82CBA8;
  border-radius: 8px; /* rounded-lg */
  justify-content: center;
  align-items: center;
  gap: 2.5px;
  display: inline-flex;

  color: white;
  font-size: 1.25rem; /* text-xl */
  font-weight: normal;
  font-family: "Reem Kufi", sans-serif;
  margin-top: 20px;
}

.gsw:hover {
  background: #237343;
}
.address {
  justify-content: start;
}
.getInTuchLayout {
  display: flex;
  flex-direction: row;
}

.conimg {
  width: 65%;
}
.navbar_paddings {
  padding: 0px 80px;
}

.mains {
  background: linear-gradient(359deg, #ffecd9 0.5%, #fff7ef 100.76%);
}

.henu {
  color: #2e3d4d;
  font-size: 1rem;
  font-weight: normal;
  font-family: "Reem Kufi";
}
.addressfont {
  color: #6b6b6b;
  font-size: 1rem;
  font-weight: normal;
  font-family: "Reem Kufi";
  color: #6b6b6b;
  font-weight: 400;
  font-family: "Reem Kufi";
}

.corprateofficetext {
  font-family: Reem kufi;
  font-weight: 800;
}
.rdOffice {
  font-family: Reem kufi;
  font-weight: 800;
  font-size: 25px;
}

.getInTuch {
  margin-left: 90px;
  color: #666666;
  font-weight: 600;
  font-family: "Reem Kufi", sans-serif;
  margin-bottom: 50px;
}
.fd {
  font-weight: bold;
  color: darkgreen;

  font-size: 2000px;
  line-height: 1.5;
}
.adressdetails {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.mz {
  font-size: 40px;
}
.contactusImg {
  height: 435px;
  width: 435px;
}
.vb {
  list-style-type: circle;
}
.ge {
  align-items: center;
}
.gf {
  color: darkorchid;
  text-decoration: underline;
}
.es {
  background-color: blueviolet;
  padding: 30px;
  display: flex;
}
.esu {
  background-color: blueviolet;
  padding: 30px;
  display: flex;
  align-items: center;
  text-align: center;
}
.getintouch {
  color: #6b6b6b;
  display: none;
}
.getintouch1 {
  color: #6b6b6b;
}

@media only screen and (max-width: 1500px) {
  .imgsize {
    width: 435px;
    height: 435px;
  }
}
@media only screen and (max-width: 1400px) {
  .imgsize {
    width: 435px;
    height: 435px;
  }
}
@media only screen and (max-width: 1200px) {
  .navbar_paddings {
    padding: 0px 50px;
  }

  .imgsize {
    width: 300px;
    height: 435px;
  }
  .haa {
    height: 35px;
  }
  .fr {
    display: flex;
    flex-direction: column;
  }

  .getintouch {
    color: #6b6b6b;
    display: flex;
  }
  .getintouch1 {
    color: #6b6b6b;
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .navbar_paddings {
    padding: 0px 50px;
  }
  .haa {
    height: 45px;
  }
  .imgsize {
    width: 300px;
    height: 435px;
  }
  /* .messagetextare {
    width: 200px;
  } */
  .thank_you {
    height: 50vh;
  }
  .fr {
    display: flex;
    flex-direction: column;
  }
  .getintouch {
    color: #6b6b6b;
    display: flex;
  }
  .getintouch1 {
    color: #6b6b6b;
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .haa {
    height: 48px;
  }
  .getintouch {
    color: #6b6b6b;
    display: flex;

    .getintouch1 {
      color: #6b6b6b;
      display: none;
    }
    .corprateofficetext {
      font-family: Reem kufi;
      font-weight: 800;
    }
  }
  .imgsize {
    width: auto;
    height: 435px;
  }
  .address {
    justify-content: start;
  }
  .ka {
    margin-right: 20px;
    padding: 0px;
  }
  .conimg {
    width: 100%;
  }
  .fr {
    display: flex;
    flex-direction: column;
  }
  .contactus {
    background: #fff7ef;
  }

  .getInTuchLayout {
    display: flex;
    flex-direction: column;
  }
  .contactusImg {
    width: 100vw;
  }
  .adressdetails {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .vgssss {
    display: flex;
    flex-direction: column;
  }
}
