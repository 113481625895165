.home_page_image_conatiner {
  /* background: linear-gradient(359deg, #ffecd9 0.5%, #fff7ef 100.76%); */
  background-color: #fff7ef;
}
.aboutUs_image {
  width: 60%;
}
.hospital_clinicians_conatiner {
  background: #fff7ef;
  padding: 0px 250px;
}

.our_team_conatiner {
  background: #659679;
}

.mentors_conatiner {
  background: #fff7ef;
  padding-left: 20px;
  padding-right: 20px;
}
.HosClinicalIcon {
  width: 80px;
  height: 80px;
}
.profile_image {
  border: 2px solid #659679;
  border-radius: 50%;
  padding: 2px;
}
.profile_card {
  margin-top: 35px;
  margin-bottom: 35px;
  /* padding: 0px 30px; */
  width: 224px;
  height: 315px;
  border-radius: 11.866px;
  background: #ffff;
  box-shadow: 0px 3.164px 18.986px -15.822px rgba(0, 0, 0, 0.4);
}
.profile_card_mentor {
  margin-top: 12px;
  margin-bottom: 45px;
  /* padding: 0px 30px; */
  width: 224px;
  height: 315px;
  border-radius: 11.866px;
  background: #ffff;
  box-shadow: 0px 3.164px 18.986px -15.822px rgba(0, 0, 0, 0.4);
}
.profile_card_padding {
  /* padding: 0px 30px; */
  padding-left: 35px;
  padding-right: 35px;
  width: 220px;
}
.profile_name {
  color: #434343;
}

.scroll_bar {
  overflow-x: auto;
}

.scroll_bar::-webkit-scrollbar-track {
  background-color: #ebeff5;
  border-radius: 10px;
}

.scroll_bar::-webkit-scrollbar {
  width: 0px;
  height: 3px;
  background-color: #ebeff5;
  border-radius: 10px;
}

.scroll_bar::-webkit-scrollbar-thumb {
  background-color: #91959d;
  border-radius: 10px;
}
.OurTeamTopLeftIcon {
  width: 80px;
  height: 80px;
}
.HosClinicalBottomRightIcon {
  width: 20px;
  height: 20px;
}

@media only screen and (max-width: 1500px) {
  .aboutUs_image {
    width: 80%;
  }
  .hospital_clinicians_conatiner {
    padding: 0px 120px;
  }
}
@media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1200px) {
  .hospital_clinicians_conatiner {
    padding: 0px 90px;
  }
}
@media only screen and (max-width: 992px) {
  .hospital_clinicians_conatiner {
    padding: 0px 60px;
  }
  .our_team_conatiner {
    background: #659679;

    padding-left: 20px;
    padding-right: 20px;
  }
  .mentors_conatiner {
    background: #fff7ef;
  }
}

@media only screen and (max-width: 576px) {
  .aboutUs_image {
    width: 100%;
  }
  .hospital_clinicians_conatiner {
    padding: 0px 20px;
  }
  .HosClinicalIcon {
    width: 35px;
    height: 35px;
  }
  .profile_card_mentor {
    margin-top: 12px;
    margin-bottom: 35px;
  }
}
