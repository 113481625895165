a {
  color: #0d6efd;
  text-decoration: underline;
}

a :hover {
  color: #0a58ca;
}

.font_family {
  font-family: "Reem Kufi";
}

/* .scroll_bar::-webkit-scrollbar-track {
  background-color: #ebeff5;
  border-radius: 10px;
}

.scroll_bar::-webkit-scrollbar {
  width: 0px;
  height: 3px;
  background-color: #ebeff5;
  border-radius: 10px;
}

.scroll_bar::-webkit-scrollbar-thumb {
  background-color: #91959d;
  border-radius: 10px;
} */

.primary_color {
  color: #6b6b6b;
}
.secondary_color {
  color: #f78c20;
}
.tertiary_color {
  color: #659679;
}
.white_color {
  color: #fff;
}
