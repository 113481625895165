@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Reem+Kufi:wght@400..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: "Reem Kufi";
 
}
@font-face {
  font-family: "Vela Sens";
  src: url("./VelaSens.ttf") format("truetype");
}
.border_none {
  border: none;
}
.bg_none {
  background: none;
}
.cursor_pointer {
  cursor: pointer;
}
/* color  */
.primary_color {
  color: #6b6b6b;
}
.secondary_color {
  color: #f78c20;
}
.tertiary_color {
  color: #534fa1;
}
.white_color {
  color: #fff;
}
/* font weight  */
.font_weigth_400 {
  font-weight: 400;
}
.font_weigth_500 {
  font-weight: 500;
}
.font_weigth_600 {
  font-weight: 600;
}
.font_weigth_700 {
  font-weight: 700;
}
/* font size   */
.fs_38 {
  font-size: 38px;
}
.fs_32 {
  font-size: 32px;
}
.fs_22 {
  font-size: 22px;
}
.fs_28 {
  font-size: 28px;
}
.fs_24 {
  font-size: 24px;
}
.fs_20 {
  font-size: 20px;
}
.fs_18 {
  font-size: 18px;
}
.fs_16 {
  font-size: 16px;
}
.fs_14 {
  font-size: 14px;
}
.fs_12 {
  font-size: 12px;
}
.fs_10 {
  font-size: 10px;
}
@media only screen and (max-width: 1200px) {
 
  .fs_lg_20 {
    font-size: 20px;
  }
}
/* font size   */
@media only screen and (max-width: 992px) {
  /* body {
    font-family: "Reem Kufi";
    overflow-x: hidden;
  } */
  .fs_md_38 {
    font-size: 38px;
  }
  .fs_md_32 {
    font-size: 32px;
  }
  .fs_md_22 {
    font-size: 22px;
  }
  .fs_md_24 {
    font-size: 24px;
  }
  .fs_md_20 {
    font-size: 20px;
  }
  .fs_md_18 {
    font-size: 18px;
  }
  .fs_md_16 {
    font-size: 16px;
  }
  .fs_md_14 {
    font-size: 14px;
  }
  .fs_md_12 {
    font-size: 12px;
  }
  .fs_md_10 {
    font-size: 10px;
  }
  .font_md_weigth_500 {
    font-weight: 500;
  }
}
@media only screen and (max-width: 576px) {
  .fs_sm_38 {
    font-size: 38px;
  }
  .fs_sm_32 {
    font-size: 32px;
  }
  .fs_sm_22 {
    font-size: 22px;
  }
  .fs_sm_24 {
    font-size: 24px;
  }
  .fs_sm_20 {
    font-size: 20px;
  }
  .fs_sm_18 {
    font-size: 18px;
  }
  .fs_sm_16 {
    font-size: 16px;
  }
  .fs_sm_14 {
    font-size: 14px;
  }
  .fs_sm_12 {
    font-size: 12px;
  }
  .fs_sm_10 {
    font-size: 10px;
  }
}
