.jeev_text {
  font-family: "Vela Sens", sans-serif;
}
.LogoImage {
  width: 75px;
  height: 76px;
}
.navbar_container {
  background-color: #fff7ef;
  z-index: 99;
}
.navbar_padding {
  padding: 5px 80px;
}
.navbar_text_padding {
  padding: 0px 25px;
}
.footer_conatiner {
  background-color: #659679;
  /* padding: 0px 112px; */
}
.footer_white_logo {
  width: 46px;
  height: 46px;
}
.follow_us_logo {
  width: 24px;
  height: 24px;
}
.social_media_icons {
  width: 28px;
  height: 28px;
}
.contact_us_btn {
  padding: 8px 42px;
  font-size: 24px;
  border-radius: 8px;
  border: 1px solid #fff;
  background-color: inherit;
  color: #fff;
}
.contact_us_btn:hover {
  padding: 8px 42px;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #e6fef0;
  color: #659679;
}
.footer_logos {
  width: 59.657px;
  height: 59.657px;
}
.copy_right_text {
  color: rgba(255, 255, 255, 0.75);
}

.offcanvas {
  width: 200px !important;
  background: #fff2e6;
}
.BottomRightIcon {
  width: 80px;
  height: 80px;
}
.MakeInIndiaIcon_footer_logos {
  width: 117px;
  height: 65px;
}

.active_tab {
  /* background: var(
    --Gradient-Gradient,
    linear-gradient(
      273deg,
      rgba(243, 161, 30, 0.12) -3.46%,
      rgba(211, 60, 82, 0.12) 30.6%,
      rgba(118, 79, 160, 0.12) 49.82%,
      rgba(102, 96, 155, 0.1) 89.27%,
      rgba(31, 172, 134, 0.12) 110.66%
    )
  );

  border-radius: 10px; */
  border-bottom: 2px solid #659679;
}

.navbar_btn {
  padding: 8px 32px;
}

.active_tab_mobile {
  background: var(
    --Gradient-Gradient,
    linear-gradient(
      273deg,
      rgba(243, 161, 30, 0.12) -3.46%,
      rgba(211, 60, 82, 0.12) 30.6%,
      rgba(118, 79, 160, 0.12) 49.82%,
      rgba(102, 96, 155, 0.1) 89.27%,
      rgba(31, 172, 134, 0.12) 110.66%
    )
  );
  padding: 8px 0px 8px 0px;
  border-radius: 10px;
  width: 100%;
}
.want_to_know_text {
  font-size: 24px;
}
@media only screen and (max-width: 1400px) {
  .navbar_text_padding {
    padding: 0px 0px;
  }
  .navbar_padding {
    padding: 5px 80px;
  }
  .want_to_know_text {
    font-size: 20px;
  }
  .contact_us_btn {
    padding: 6px 30px;
    font-size: 20px;
  }
  .contact_us_btn:hover {
    padding: 6px 30px;
    font-size: 20px;
  }
}
@media only screen and (max-width: 1200px) {
  .navbar_text_padding {
    padding: 0px 0px;
  }
  .navbar_padding {
    padding: 5px 80px;
  }
  .want_to_know_text {
    font-size: 18px;
  }
  .contact_us_btn {
    padding: 6px 20px;
  }
  .contact_us_btn:hover {
    padding: 6px 20px;
  }
  .BottomRightIcon {
    width: 60px;
    height: 60px;
  }
}
@media only screen and (max-width: 992px) {
  .navbar_text_padding {
    padding: 0px 0px;
  }
  .navbar_padding {
    padding: 5px 30px;
  }
  .BottomRightIcon {
    width: 40px;
    height: 40px;
  }
}
@media only screen and (max-width: 768px) {
  .navbar_text_padding {
    padding: 0px 0px;
  }
  .navbar_padding {
    padding: 5px 30px;
  }
  .navbar_btn {
    padding: 8px 20px;
  }
  .BottomRightIcon {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .navbar_text_padding {
    padding: 0px 0px;
  }
  .navbar_padding {
    padding: 5px 12px;
  }
  .footer_white_logo {
    width: 32px;
    height: 32px;
  }
  .follow_us_logo {
    width: 16px;
    height: 16px;
  }
  .social_media_icons {
    width: 22px;
    height: 22px;
  }
  .footer_logos {
    width: 44.743px;
    height: 44.743px;
  }
  .BottomRightIcon {
    width: 26px;
    height: 26px;
  }
  .MakeInIndiaIcon_footer_logos {
    width: 83.455px;
    height: 45px;
  }
  .LogoImage {
    width: 61px;
    height: 62px;
  }
  .want_to_know_text {
    font-size: 16px;
  }
  .contact_us_btn {
    font-size: 14px;
  }
  .contact_us_btn:hover {
    font-size: 14px;
  }
}
