.home_page_image_conatiner {
  /* background: linear-gradient(359deg, #ffecd9 0.5%, #fff7ef 100.76%); */
  background-color: #fff7ef;
}
.homepage_image {
  width: 60%;
}
.bussines_man_image {
  width: 70%;
  object-fit: contain;
}
.ourProducts_container {
  background-color: #fff7ef;
}
.management_assistant_card {
  /* border-radius: 20px; */
  /* background-color: #fff1e3; */
  /* margin: 0px 120px; */
  margin-left: 220px;
  margin-right: 220px;
  padding: 20px 40px;
}

.need_a_demo_card {
  background-color: #659679;
}

.schedule_button {
  border-radius: 8px;
  background: #fff;
  padding: 12px 48px;
  color: #659679;
}

.schedule_button:hover {
  border-radius: 8px;
  background: #e6fef0;
  padding: 12px 48px;
  color: #659679;
}
.needADemoBottomLeftIcon {
  width: 146px;
  height: 145px;
}
.ourCustomers_container {
  background: #fff7ef;
}
.customeImage {
  width: 80%;
}
.ManageAssistLeftTop {
  width: 107px;
  height: 107px;
}
.assistant_icons {
  width: 28px;
  height: 28px;
}
.ourCustomerCard {
  border-radius: 20px;
  width: 23vw;
  /* height: 20vh; */
  background-color: white;
}
.ourCustomerContainer {
  margin-left: 200px;
  margin-right: 200px;
}
.ourCustomerText {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 20px;
}
@media only screen and (max-width: 1500px) {
  .homepage_image {
    width: 80%;
  }
  .management_assistant_card {
    /* margin: 0px 70px; */
    margin-left: 70px;
    margin-right: 70px;
  }
  .ourCustomerContainer {
    margin-left: 70px;
    margin-right: 70px;
  }
  .bussines_man_image {
    width: 80%;
    object-fit: contain;
  }
}
@media only screen and (max-width: 1400px) {
  .bussines_man_image {
    width: 90%;
    object-fit: contain;
  }
  .ourCustomerCard {
    width: 25vw;
  }
}
@media only screen and (max-width: 1200px) {
  .bussines_man_image {
    width: 100%;
    object-fit: contain;
  }
  .customeImage {
    width: 90%;
  }
  .ourCustomerContainer {
    margin-left: 30px;
    margin-right: 30px;
  }
  .ourCustomerCard {
    width: 35vw;
  }

}
@media only screen and (max-width: 992px) {
  .management_assistant_card {
    /* margin: 0px 30px; */
    margin-left: 30px;
    margin-right: 30px;
  }
  .ourCustomerCard {
    width: 38vw;
  }
 
}

@media only screen and (max-width: 768px) {
  .ourCustomerCard {
    width: 80vw;
  }
 
}

@media only screen and (max-width: 576px) {
  .taransform_text {
    padding: 0px 15px;
  }
  .homepage_image {
    width: 100%;
  }
  .management_assistant_card {
    /* margin: 0px 15px; */
    margin-left: 15px;
    margin-right: 15px;
    padding: 20px 10px;
  }
  .ourCustomerContainer {
    margin-left: 15px;
    margin-right: 15px;
  }
  .ourCustomerCard {
    width: 90vw;
  }
  .needADemoBottomLeftIcon {
    width: 79px;
    height: 83px;
  }
  .ManageAssistLeftTop {
    width: 50px;
    height: 50px;
  }
  .assistant_icons {
    width: 18px;
    height: 18px;
  }
}
